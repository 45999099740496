// share-this
const socialTrigger = document.querySelector("button.share-this");
const socialDisplay = document.querySelector(".socials");

socialTrigger.addEventListener("click", () => {
  socialTrigger.classList.toggle("share-open");
  socialDisplay.classList.toggle("social-open");
});


// copy url to clipboard
var $temp = jQuery("<input>");
var $url = jQuery(location).attr('href');

jQuery('.clipboard').on('click', function() {
  jQuery("body").append($temp);
  $temp.val($url).select();
  document.execCommand("copy");
  $temp.remove();
  jQuery('.copy-msg').show();
  jQuery('.copy-msg').delay(2000).fadeOut(500);
  //jQuery("p").text("URL copied!");
})